.container {
    width: 100%;
    margin-bottom: 20px;

    .content {
        width: 100%;
        background-color: #fff;
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        .contentHeader {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }

        h2 {
            margin: 0px;
            font-size: 20px;
        }

        section {
            margin: 7px 0;
        }

        .searchBlock {
            width: 100%;
            margin: 10px 0;
        }

        .blockProducts {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;

            .productItem {
                width: 100%;
                border-radius: 5px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                cursor: pointer;
                transition: 0.2s all;

                &:hover {
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                    transition: 0.2s all;
                }

                .syncStatusBlock {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 25px;
                    height: 25px;
                    background: #70b129;
                    border-radius: 50%;
                    z-index: 1000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    color: #fff;
                }

                h3 {
                    margin: 0;
                }

                img {
                    width: 100%;
                    height: auto;
                }

                p {
                    font-weight: bold;
                    margin: 0;
                }

                .Dropdown {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    z-index: 100;
                    display: flex;
                    width: 25px;
                    height: 25px;
                    background: rgba(88, 108, 233, 0.03);
                    border-radius: 7px;
                    justify-content: center;
                    align-items: center;
                    color: #333;
                    transition: 0.2s all;
                }
            }
        }

        .pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px 0 0;
        }

        .spaceContainer {
            width: 100%;
            padding: 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 2px dashed rgb(228, 228, 228);
            border-radius: 7px;

            .icon {
                font-size: 35px;
                color: rgba(0, 0, 0, 0.2);
            }
            p {
                margin: 10px 0 0 0;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.4);
                font-size: 18px;
            }
        }
    }
}

.formSection {
    width: 100%;
    margin: 5px 0;

    p {
        margin: 0px;
    }

    .formSectionRows {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        .button {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 15px 10px;
            cursor: pointer;
            position: relative;
            border: 1px dashed #333;

            .buttonContent {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 15px 10px;
                cursor: pointer;
                position: relative;
            }

            .colorsBlock {
                width: 100%;
                background-color: #f6f6f6;
                left: 0px;
                position: absolute;
                box-shadow: 0 0 20px #d2d2d2;
                z-index: 111;
                opacity: 0;
                visibility: hidden;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                transform: scale(0);

                transition: 0.3s all;

                div {
                    width: 100%;
                    height: 70px;
                }

                .color1 {
                    background-color: rgb(255, 255, 255);
                }
                .color2 {
                    background-color: rgb(233, 214, 190);
                }
                .color3 {
                    background-color: rgb(254, 203, 218);
                }
                .color4 {
                    background-color: rgb(102, 197, 253);
                }
                .color5 {
                    background-color: rgb(128, 128, 128);
                }
                .color6 {
                    background-color: rgb(254, 211, 48);
                }
                .color7 {
                    background-color: rgb(194, 24, 168);
                }
                .color8 {
                    background-color: rgb(60, 212, 198);
                }
                .color9 {
                    background-color: rgb(0, 0, 0);
                }
                .color10 {
                    background-color: rgb(253, 144, 38);
                }
                .color11 {
                    background-color: rgb(90, 19, 154);
                }
                .color12 {
                    background-color: rgb(125, 229, 45);
                }
                .color13 {
                    background-color: rgb(119, 60, 11);
                }
                .color14 {
                    background-color: rgb(190, 7, 18);
                }
                .color15 {
                    background-color: rgb(42, 86, 215);
                }
                .color16 {
                    background-color: rgb(53, 101, 13);
                }
            }

            .colorsBlockOpened {
                bottom: 100%;
                left: 100%;
                transform: scale(1);
                opacity: 1;
                visibility: visible;

                transition: 0.3s all;
            }
        }
    }

    .formSectionSelect {
        width: 100%;
    }

    .formSectionMaskInput {
        width: 100%;
        outline: none;
        height: 40px;
        border: 1px solid #e1e1e1;
        padding: 0 10px;
    }

    .formSectionUploadFile {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        padding: 10px 0 0;
        box-sizing: border-box;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        .imageProduct {
            width: 100%;
            border: 1px dashed rgba(0, 0, 0, 0.4);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }

            .controllImage {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;

                span {
                    width: 100%;
                    display: flex;
                    cursor: pointer;
                    height: 35px;
                    background-color: #5b6ee9;
                    color: #fff;
                    justify-content: center;
                    align-items: center;
                    transition: 0.2s all;

                    &:hover {
                        background-color: #4e5bb0;
                        transition: 0.2s all;
                    }
                }
            }
        }

        h3 {
            margin: 0;
            font-size: 15px;
            font-weight: normal;
            color: #b8b8b8;
        }

        .photosProduct {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;

            .photoProduct {
                width: 100%;
                height: 200px;
                border-radius: 6px;
                border: 3px solid #e7e7e7;
                position: relative;

                div {
                    cursor: pointer;
                    width: 100%;
                    padding: 10px;
                    font-size: 12px;
                    background: #fff;
                    top: 0;
                    left: 0;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 6px;
                }

                div.MainPhoto {
                    background: orange;
                }

                span.deletePhoto {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    z-index: 100;
                    cursor: pointer;
                    color: red;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .formSectionFile {
            display: flex;
            cursor: pointer;
            margin: 4px 0;
            position: relative;
            width: fit-content;

            span {
                color: #fff;
            }

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 11;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
}

.formSectionBordered {
    border: 1px dashed #333;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    position: relative;

    input {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 111;
        opacity: 0;
    }
}

.formSectionOther {
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #dedede;

    p {
        margin-bottom: 10px;
    }

    h5 {
        margin: 10px 0 0;
    }

    .paramentItem {
        width: 85%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        margin: 5px 0;
        background: #eee;
        position: relative;

        .paramentItemDelete {
            position: absolute;
            right: -20px;
            color: #de2828;
            font-size: 17px;
            cursor: pointer;
        }

        h6 {
            font-size: 15px;
            margin: 0;
            font-weight: normal;
            color: #797979;
        }
        b {
            width: 40%;
            margin: 0;
            font-size: 15px;
        }
        span {
            margin: 0;
        }
    }
}
