.container {
    width: 100%;
    margin: 10px 0;
    box-shadow: 0 3px 6px rgba(0,0,0, 0.14);
    box-sizing: border-box;
    padding: 10px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 10px;
    z-index: 10;
    background: #f0f2f5;

    .left {
        width: 25%;
        padding: 0 25px;
        margin-right: 15px;
    }

    .right {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-weight: 600;
            font-size: 18px;
            color: #0a2463;
            margin: 0;
        }

        .merchantLogo {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            p {
                font-weight: 600;
                font-size: 18px;
                color: #0a2463;
                margin: 0;
            }

            .logo {
                cursor: pointer;
                width: 50px;
                height: 50px;
                background-color: #eee;
                border-radius: 50%;
                margin: 0 10px;
                box-shadow: 0 0 10px rgba(0,0,0,0.3);

                input {
                    width: 50px;
                    height: 50px;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }
    }
}