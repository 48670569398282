.container {
    width: 100%;

    .content {
        width: 100%;
        background-color: #fff;
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);

        h2 {
            margin: 0px;
            font-size: 20px;
        }

        section {
            margin: 7px 0;
        }

        .list {
            width: 100%;
        }

        p {
            margin: 0px;
        }

        .listShops {
            width: 100%;
            display: flex;
            flex-direction: row;
        
            .listShopsContent {
                display: flex;
                flex-direction: row;
                position: relative;
                margin: 0 10px;
        
                .shopItem {
                    width: 25px;
                    height: 25px;
                    top: -1px;
                    position: absolute;
                    border-radius: 50%;
                    box-shadow: 0 0 7px rgba(0,0,0,0.4);
                    cursor: pointer;
        
                    transform: scale(1);
                    transition: .1s all;
        
                    &:hover {
                        box-shadow: 0 0 8px rgba(0,0,0,0.36);
                        z-index: 100;
                        transform: scale(1.3);
                        transition: .09s all;
                    }
                }
            }
        }

    }
}

.popoverContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 9999999999999;

    span {
        margin: 0 0 4px 0;
    }
}

.itemCheck {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .itemCheckLeft {
        width: 98%;
        margin-left: 2%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
}