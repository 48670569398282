
@font-face {
    font-family: "ProximaNova";
    src: url(./fonts/ProximaNova-Regular.ttf);
    font-display: swap;
}
@font-face {
    font-family: "ProximaNova";
    src: url(./fonts/ProximaNova-Bold.ttf);
    font-weight: 600;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: "ProximaNova";
    font-weight: 300;
}

body {
    background-color: #f2f4f6;
    overflow-y: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #333;
}

::-webkit-scrollbar-thumb {
	background-color: #bbb;
	border-radius: 20px;
}

::-moz-scrollbar {
	width: 8px;
}

::-moz-scrollbar-track {
	background: #333;
}

::-moz-scrollbar-thumb {
	background-color: #222;
	border-radius: 20px;
}

::-o-scrollbar {
	width: 8px;
}

::-o-scrollbar-track {
	background: #333;
}

::-o-scrollbar-thumb {
	background-color: #222;
	border-radius: 20px;
}

::-ms-scrollbar {
	width: 8px;
}

::-ms-scrollbar-track {
	background: #333;
}

::-ms-scrollbar-thumb {
	background-color: #222;
	border-radius: 20px;
}