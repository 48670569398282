.container {
    width: 100%;

    .content {
        width: 100%;
        background-color: #fff;
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        h2 {
            margin: 0px;
            font-size: 20px;
        }

        section {
            margin: 7px 0;
        }
    }

    .contentSync {
        width: 100%;

        .contentSyncHeader {
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
        }
    }
}

.descriptionTextSync {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
