.container {
    width: 100%;
    margin-bottom: 20px;

    .content {
        width: 100%;
        background-color: #fff;
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);

        .contentHeader {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            h2 {
                margin: 0px;
            }
        }

        .filter {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 2.02fr;
            grid-gap: 5px;
        }

        .chart {
            margin: 15px 0 0;
        }

        .blockStats {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 5px;
            border-radius: 5px;
            box-sizing: border-box;
            margin-top: 5px;

            .blockStatsItem {
                width: 100%;
                border: 1px dashed rgba(0,0,0,0.4);
                border-radius: 5px;
                padding: 5px 10px;

                h5 {
                    margin: 0px;
                    font-weight: bold;
                    font-size: 14px;

                    small {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.spaceContainer {
    width: 100%;
    padding: 10px 0;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 7px;

    .icon {
        font-size: 35px;
        color: rgba(0,0,0,0.2);
    }
    p {
        margin: 10px 0 0 0;
        text-transform: uppercase;
        color: rgba(0,0,0,0.4);
        font-size: 18px;
    }
}