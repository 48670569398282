.layer {
    width: 70vw;
    margin: 0 auto;
    min-height: 100vh;

    @media screen and (max-width: 1920px) {
        width: 98vw;
    }

    .wrapper {
        width: 100%;

        .container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 15px;

            .content {
                width: calc(100% - 15px);
            }
        }
    }
}

.authLayer {
    width: 100vw;
    min-height: 100vh;
    background: url(../../../Assets/login-background.jpg) top / cover no-repeat;
}
