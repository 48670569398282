.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loaderIcon {
        font-size: 44px;
        color: #888;
    }
}